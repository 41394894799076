@import 'styles/variables';

.horizontalList {

    &__list {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        box-sizing: border-box;
        overflow-x: scroll;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            display: none;
        }

        > li {
            flex: none;
            padding: 0;
            margin: 0;

            &:last-child {
                .playlist-item__song-detail--gallery {
                    margin-right: 0;
                }
            }
        }
    }
}

// desktop 
.horizontalList.disabled {
    .horizontalList {
        &__list {
            display: block;

            > li {
                display: inline-block;
                margin-right: 2rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

// desktop specific
.app--desktop {
    .home__left-col {
        .song-list-item__song-detail {
            h5 {
                font-size: $font-size-m;
                font-weight: normal;
            }
        }
    }
}