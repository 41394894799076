@import 'styles/variables';

.share-bar {
    display: flex;
    padding: 1rem;

    &--preload {
        min-height: 71px;
    }

    button {
        margin-right: 0.5rem;

        &:hover {
            path {
                fill: $primary-green;
            }
        }
    }
}