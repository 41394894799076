@import 'styles/mixin';

.app {
  &.bg-home {
    background-image: url("../../assets/images/bg_3038659.jpg");
    background-repeat: no-repeat;
    background-size: auto 300px;

    @include breakpoint(sm) {
      background-size: 100% 500px;
    }
  }
}
