@import 'styles/variables';

.share-link {
    &__btn {
        color: $white !important;
    }

    &__icon {
        // margin-top: -4px;
        transform: rotate(-45deg);
    }

    &__input {
        display: none;
    }
}
