@import 'styles/variables';
@import 'styles/mixin';

.progress-bar {
    width: 100%;
    overflow: hidden;

    .progress-bar__progress {
        height: 2px;
        width: 0;
        background-color: $primary-green;
    }
}