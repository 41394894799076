@import 'styles/variables';
@import 'styles/mixin';

.side-bar {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    right: 0;
    // bottom: -80px;
    bottom: 0px;
    z-index: 99;
    padding: 0;
    margin: 0;
    transition: bottom 1s;

    &.in-view {
        bottom: 0;
    }

    @include breakpoint(sm) {
        bottom: 2rem;
        right: 2rem;
    }

    > li {
        display: block;
        text-align: center;
        margin: 0 0 1rem 0;
        padding: 0;

        &:first-child {
            margin-bottom: 0.25rem;
        }
    }

    small {
        display: block;
        font-size: $font-size-xs;
        line-height: normal;
        text-align: center;
        color: $grey-200;
        margin-top: -4px;
    }

    .btn-more {
        color: $white;
    }
}