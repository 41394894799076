@import 'styles/mixin';
@import 'styles/variables';

.creat-list {
    li  {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
    li > .MuiButtonBase-root {
        border: 1px solid rgba($primary-green, 0.5);
        border-radius: $roundBorder;
    }

    .MuiListItemIcon-root {
        svg {
            fill:  $primary-green;
            width: 34px;
            height: 34px;
        }
    }

    .MuiListItemText-primary {
        font-size: $font-size-l;
        color: $primary-green;
    }

    .MuiListItemText-secondary {
        font-size: $font-size-s;
        color: $text-hint;
    }
}