.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    box-sizing: border-box;

    &--cover {
        width: 100%;
        height: 100%;
        padding: 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999;
    }
}