// colour
$white: #fff;
$grey-200: #ccc;
$grey-400: #878787;
$grey-600: #575757;
$grey-800: #333333;
$black: #000000;
$black-800: #292929;
$red: #d32f2f;
$chill-400: #C749C9;
$sad-400: #6A82FF;
$energy-400: #DF8114;

$primary-green: #ADD038;
$primary-green-hover: #caf834;

$text-primary: #ccc;
$text-secondary: #e6e6e6;
$text-hint: #999;

$divider: rgba(255,255,255,0.3);
$border-grey-600: #333333;

// dark color palette
$cover-green: #0f3b0f;
$cover-red: #3b0f0f;
$cover-blue: #0f2c3b;
$cover-purple: #2e0f3b;
$cover-pink: #3b0f30;

$roundBorder: 1rem;
$font-size-xl: 1.188em;
$font-size-l: 1.125em;
$font-size-m: 1em;
$font-size-sm: 0.875em;
$font-size-s: 0.8125em;
$font-size-xs: 0.75em;
$font-lighter: 300;

$slideDialog-border-top: 1px solid rgba($white,0.4);
$slideDialog-border-radius: 8px 8px 0 0;
$slideDialog-box-shadow: 0px -2px 6px rgba($black,0.5);
