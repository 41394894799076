
.music-item {
    display: flex;

    &__img {
        width: 60px;
        height: 60px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &__description {
        padding-left: 1rem;
        max-width: 80%;
        flex: 1;
    }

    h4 {
        margin: 0;

        &.text-truncated {
            max-width: 80%;
        }
    }
}