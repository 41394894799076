@import 'styles/variables';
@import 'styles/mixin';

$smallText: 10px;

.miniPlayer {
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to bottom,  rgba(0,0,0,0.75) 0%,rgba(0,0,0,1) 36%);
    border-top: $slideDialog-border-top;
    border-radius: $slideDialog-border-radius;
    overflow: hidden;
    box-shadow: $slideDialog-box-shadow;

    @include breakpoint(sm) {
        max-width: 80%; // 1200px;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
    }

    &::before {
        display: block;
        content: "";
        height: 4px;
        width: 40px;
        background-color: $grey-600;
        margin: 8px auto 0 auto;
        border-radius: 4px;
    }

    h5, 
    small {
        display: block;
        margin: 0;
    }

    small {
        font-size: $smallText;
    }

    &__col-details {
        display: flex;
        align-items: center;
    }

    &__music-img {
        width: 60px;
        height: 60px;
        background-size: cover;
        background-color: $grey-800;
        border-radius: 4px;
        margin-right: 12px;
    }

    &__description {
        max-width: 67%;
    }

    &__listened {
        display: flex;
        align-items: center;
        font-size: 10px;

        svg {
            font-size: 12px;
        }
    }

    &__controls {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        padding-top: 8px;

        button {
            color: $grey-200;
            margin-left: 0.5rem;
            padding: 4px;
        }

        .controls button.controls__btn-play {
            margin-top: -6px;

            svg {
                font-size: 2.2rem;
            }
        }
    }

    &__like {
        text-align: center;

        button {
            margin-left: 0;
        }
    }

    &__btn-like,
    .share-button__icon-button {
        svg {
            font-size: $font-size-s;
        }
    }

    .progress-bar {
        margin-top: 0.5rem;
    }

    &__skeleton {
        padding-bottom: 4px;
    }
}

// desktop style
.miniPlayer--desktop {
    border: $slideDialog-border-top;
    border-bottom: none;

    .desktop-layout__main-container {
        max-width: 100%!important;
    }

    &::before {
        background-color: transparent;
        margin: 0;
    }

    button {
        opacity: 0.8;
        transition: opacity 0.3s;
    }
    button:hover {
        opacity: 1;
    }

    .miniPlayer {  
        .controls {
            width: 100%;
        }

        &__col-right {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
        }

        &__like-contianer {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
        }

        &__controls {
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }

        &__col-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
        }
    }

    .progress-Input-range {
        width: 90%;

        small {
            margin: 0 0.5rem;
        }
    }

    .btn_full-screen {
        color: $white;
        padding: 0;
        opacity: 0.9;
    }

    .btn-more {
        color: $grey-200;
        padding-top: 5px;
    }
}

// volume control component
// ref https://webspe.net/tools/en/input-range/
.volume-control {
    &__button.MuiButtonBase-root {
        color: $white;
    }

    &__input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        width: 100px;
        height: 6px;
        border: none;
        background: transparent;
        border-radius: 9999px;
        overflow: hidden;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            background-color: $grey-800;
            border-radius: 0.5rem;
            height: 6px;
        }
      
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: $white;
            color: $white;
            box-shadow: -100px 0px 0px 97px;

            &:hover {
                color: $primary-green;
            }
        }
        
        /* Thumb: for Firefox */
        &::-moz-range-thumb {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: $white;
            color: $white;
            box-shadow: -100px 0px 0px 97px;

            &:hover {
                color: $primary-green;
            }
        }
    }
}