@import 'styles/variables';
@import 'styles/mixin';

button.create-button {
    min-width: 50%;

    @include breakpoint(sm) {
        min-width: 220px;
        font-size: $font-size-xl
    }

    svg  {
        fill: inherit;
        height: 1.25rem;
    }
}