@import 'styles/variables';
@import 'styles/mixin';

.add-song {
    &__nav.css-1mk9mw3-MuiList-root {
        margin-left: -12px;
        margin-right: -12px;
    }
}

.addToPlaylist-form {
    &__music-item {
        margin-bottom: 1.5rem;
    }

    &__playlists.MuiFormControl-root {
        margin-top: 0.75rem;
        margin-bottom: 0.5rem;
    }
}