@import 'styles/variables';
@import 'styles/mixin';

.lyric-Panel {
    h3.lyric-Panel__title {
        margin: 1rem 0;
        font-size: $font-size-m;
        font-weight: bold;
        text-align: center;


        @include breakpoint(sm) {
            font-size: $font-size-xl;
        }
    }

    &__pre-line {
        white-space: pre-line;
        font-family: 'Roboto';
        font-size: $font-size-s;
        text-align: center;
        color: $grey-200;
        padding: 0;
        margin: 0;

        @include breakpoint(sm) {
            font-size: $font-size-l;
        }
    }
}