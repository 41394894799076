@import 'styles/variables';
@import 'styles/mixin';

.prompt-panel {
    h3.prompt-panel__title {
        font-size: $font-size-m;
        font-weight: bold;
        text-align: center;


        @include breakpoint(sm) {
            margin-bottom: 1rem;
        }
    }

    &__artists {
        display: flex;
        justify-content: center;
    }

    &__artist {
        margin: 0 0.5rem;
        text-align: center;
    }

    &__artist-img {
        display: inline-block;
        width: 40px;
        height: 40px;
        background-position: center center;
        background-size: cover;
        border-radius: 50%;
    }

    &__prompt {
        display: block;
        padding: 0.5rem;
        font-family: monospace, 'Roboto';
        border-radius: 4px;
        background-color: rgba(0,0,0,0.15);

        @include breakpoint(sm) {
            padding: 1rem;
        }
    }
}
