@import 'styles/variables';
@import 'styles/mixin';

.controls {
    button {
        color: $grey-200;
        margin: 0 0.25rem;

        &.Mui-disabled {
            color: $grey-200; 
            opacity: 0.3;
        }

        &.controls__btn-play {
            color: $white;
    
            svg {
                font-size: 3.5rem;
            }
        }

        &.controls__btn-shuffle,
        &.controls__btn-repeat {
            svg {
                font-size: 26px;
            }

            &.active {
                color: $primary-green;
            }
        }

        &.controls__btn-repeat {
            position: relative;
        }
    }

    &__repeat-single {
        font-size: 10px;
        font-weight: bold;
        position: absolute;
    }

    &__buttons {
        display: flex;
        justify-content: center;

        svg {
            font-size: 2rem;
        }
    }


    &__volume {
        display: flex;
        align-items: center;
    }

    &__vol-input {
        width: 50px;
    }
}