@import 'styles/mixin';

.home {
    &__intro {
        text-align: center;

        @include breakpoint(sm) {
            text-align: left;
        }
    }

    &__intro-butons-section {
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;

        @include breakpoint(sm) {
            justify-content: flex-start;
        }

        button {
            width: 45%;

            @include breakpoint(sm) {
                width: auto;
                margin-right: 2rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    h2 {
        margin: 2rem 1rem;
    }

    
}

// desktop style
.app--desktop {
    .home {
        &__left-col .horizontalList.disabled,
        &__right-col {
            // height: 73vh;
            overflow-y: auto;
        }

        &__intro {
            min-height: 204px;
        }
    }
}
