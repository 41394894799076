@import 'styles/variables';
@import 'styles/mixin';

$blurSize: 100px;

.music-detail-dialog {
    &__wrap {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-grow: 1;
    }

    &__wrap-inner {
        height: 100%;
        width: 100%;
        -webkit-backdrop-filter: blur($blurSize) brightness(0.5) hue-rotate(180deg);
        backdrop-filter: blur($blurSize) brightness(0.5) hue-rotate(180deg);
        transform: translate3d(0, 0, 0); // trigger hardware GPU
    }

    &__content.MuiDialogContent-root {
        padding-left: 34px;
        padding-right: 34px;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        padding: 0 0 0 0px !important;
    }

    &__btn-close {
        color: $white!important;
        padding-left: 16px !important;

        > svg {
            font-size: 2rem;
        }
    }
}

// make dialog scrollable
.MuiDialog-paperScrollPaper {
    overflow: auto;
}

// desktop style
.dialog-desktop {
    .music-detail-dialog {
        &__title {
            display: block;
            text-align: right;
        }

        &__content {
            min-width: 600px;
        }
    }
}


// mustic-details-dialog - full screen music details
.music-details__dialog {
    &.dialog-desktop {
        h2.music-detail-dialog__title {
            display: block;
            text-align: right;
        }

        .music-detail-dialog__btn-close {
            padding-right: 16px;
        }
    }
}