@import 'styles/variables';
@import 'styles/mixin';

.main-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    position: relative;
    width: 100%;
}

button.main-header__btn-drawer,
button.main-header__btn-back {
    color: $grey-400;
    position: absolute;
    left: 0.5rem;
    top: 1rem;

    &:hover {
        color: $white;
    }
}

button.main-header__btn-drawer {
    top: 0.5rem;
    > svg {
        width: 32px;
        height: 32px;
    }
}

// desktop
.app--desktop {
    .main-header--sticky {
        .main-header--desktop {
            position: fixed;
            z-index: 999;
            background-color: $black;
            filter: drop-shadow(0px 8px 8px rgba(0,0,0,0.7));
        }
    }
}
.main-header--desktop {
    transition: background-color 1s;

    .main-header {
        &__desktop-container {
            max-width: 80%!important;
        }

        &__logo {
            display: flex;
            align-items: center;
        }
    }

    .logo-wiretree-ai {
        width: 64px;
        height: 64px;
        margin-left: 15px;
    }
}