@import '@fontsource/roboto/latin.css'; // Latin subset
@import '@fontsource/roboto/latin-italic.css';

@import './variables';
@import './mixin';
@import './utils';
@import './form';

html, 
body,
#root {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
}
.app {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $black!important;
  color: $text-primary!important;
  font-size: 14px;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

li, dt, dd {
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 1.6rem;
  color: $primary-green;
}

h2 {
  font-size: 1.4rem;;
}

.text-s,
small {
  font-size: $font-size-s;
}

.text-xs {
  font-size: $font-size-xs;
}

.divider {
  border-top: 1px solid $divider;
  height: 1px;
  margin: 1rem 0;
}

.sr-only {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}

// MUI
label.MuiInputLabel-root {
  &.Mui-error {
    color: $grey-400;
  } 
}

.MuiOutlinedInput-root {
  &.Mui-error {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $grey-400;
    }
  }
}

textarea.MuiInputBase-inputMultiline {
  min-height: 3rem;
}

.text-truncated {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-center {
  text-align: center;
}

.submit-button.Mui-disabled {
  background-color: $primary-green!important;
}

.pl-0 {
  padding-left: 0!important;
}

h1, h2, h3 {
  font-weight: $font-lighter;

  @include breakpoint(sm) {
    font-weight: bold;
  }
}

a {
  color: $primary-green;
}

.bold {
  font-weight: bold;
}

button.btn-link {
  color: $text-hint;
  text-transform: none;
  text-decoration: underline;
  font-weight: normal;

  &:hover {
    color: $primary-green;
  }
}

// generic components
.input-toggle {
  color: $text-hint;
}

// MUI theme
span.MuiSwitch-track {
  background-color: $text-secondary;
}

.static-page {

}

.app--desktop {
  /* ===== Scrollbar CSS ===== */
  // https://codepen.io/stephenpaton-tech/full/JjRvGmY
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #616161 rgba(0,0,0,0.1);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.1);
  }

  *::-webkit-scrollbar-thumb {
    background-color: #616161;
    border-radius: 10px;
    border: 3px solid #000000;
  }

  .page-main-container {
    max-width: 80%!important;
  }
}

.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline {
  border-color: $grey-400!important;
}

.MuiAlert-standardInfo a {
  color: $cover-green;
}