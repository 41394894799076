@import 'styles/mixin';
@import 'styles/variables';

.song-list {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;

    h3.small {
        margin-left: 1rem;

        @include breakpoint(sm) {
            font-size: $font-size-m;
        }
    }

    &__list {
        > li {
            border-top: 1px solid $border-grey-600;
            border-collapse: collapse;
            padding: 1rem 0;
            margin: 0 1px;

            &:active {
                outline: 1px solid $primary-green;
                border-radius: 0.5rem;
            }

            @include hover-supported() {
                &:hover {
                    outline: 1px solid $primary-green;
                    border-radius: 0.5rem;
                }
            }
        }
    }

    &__list--grid {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(auto-fit, minmax(min(9rem, 100%), 1fr));

        > li {
            border: none;
            padding: 0;
        }

        @include breakpoint(sm) {
            gap: 2rem;
        }

        @include breakpoint(lg) {
            grid-template-columns: repeat(6, 1fr); /* 6 columns for medium and larger screens */

            .song-list-item__song-img {
                width: 10vw; 
                height: 10vw;
            }
        }
    }
}

.pending-list {
    > li {
        border-top: 1px solid $border-grey-600;
        border-collapse: collapse;
        padding: 1rem;
    }
}