@import 'styles/variables';
@import 'styles/mixin';

.music-details {
    text-align: center;
    color: $grey-200;

    &__music-img {
        width: 280px;
        height: 280px;
        margin: 0 auto;
        background-color: $grey-800;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 4px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5)
    }

    h3 {
        margin-bottom: 0;
        color: $white;
    }

    small {
        display: block;
    }

    .controls {
        margin-bottom: 1rem;
    }

    &__container {
        text-align: left;
        margin: 0 auto;

        @include breakpoint(sm) {
            font-size: $font-size-m;
        }
    }
}

// desktop style
.music-details.desktop {
    .music-details {
        &__container {
            flex-direction: row-reverse;
            margin-top: 3rem;
        }
    }
}