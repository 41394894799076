@import 'styles/mixin';
@import 'styles/variables';

.playlist-toggle {
    display: flex;
    justify-content: space-between;

    &__title {
        display: block;
        padding: 0;
        margin: 0;

        @include breakpoint(sm) {
            margin-left: 1rem;
        }
    }

    button {
        text-transform: none;
        font-weight: $font-lighter;
        font-size: $font-size-m;
        color: $text-hint;
        padding-left: 1rem;
        padding-right: 1rem;

        &.Mui-selected {
            color: $primary-green;
        }
    }

    &__view-toggle {
        button {
            padding: 0 4px;
            border: none;
        }
    }
}
