@import 'styles/variables';
@import 'styles/mixin';

.playlist-cover {
    border: none;
    position: relative;
    width: 140px;
    height: 140px;
    margin: 0 auto;
    box-shadow: 6px 6px 1px rgba(255,255,255, 0.2);

    &::before {
        content: "";
        display: block;
        width: 140px;
        height: 140px;
        margin: -6px 0 0 -6px;
        background-image: url("../../assets/wisetree_logo_white.svg");
        background-position: 40px -45px;
        background-repeat: no-repeat;
        position: absolute;
        opacity: .3;
    }

    &::after {
        display: block;
        content: "";
        width: 140px;
        height: 140px;
        margin: -6px;
        background-color: transparent;
        box-shadow: 3px 3px 0px $grey-600;
        z-index: -1;
    }

    &__title {
        display: block;
        position: absolute;
        width: 94px;
        color: $white;
        font-size: $font-size-s;
        bottom: 8px;
        left: 12px;
        text-align: left;
    }
}

.cover-color {
    &_0 {
        background-color: $cover-green;
    }
    &_1 {
        background-color: $cover-red;
    }
    &_2 {
        background-color: $cover-blue;
    }
    &_3 {
        background-color: $cover-purple;
    }
    &_4 {
        background-color: $cover-pink;
    }
}

.cover-art {
    background-color: $grey-800;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &::before {
        background: none;
    }
}