@import 'styles/variables';
@import 'styles/mixin';


.slide-dialog {
    .MuiDialog-container {
        align-items: flex-end;
    }

    .MuiPaper-root {
        margin: 0;
        width: 100%;
        min-height: 240px;
        border-top: $slideDialog-border-top;
        border-radius: $slideDialog-border-radius;
        box-shadow: $slideDialog-box-shadow;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
    }

    &__btn-close {
        display: block;
        border: none;
        background: none;
        width: 100%;
        cursor: pointer;

        &::before {
            background-color: $grey-600;
            border-radius: 4px;
            content: "";
            display: block;
            height: 4px;
            margin: 8px auto;
            width: 40px;
        }
    }

    .MuiDialogContent-root,
    .MuiListItemIcon-root {
        color: $text-primary;
    }

    .MuiListItemIcon-root {
        min-width: 48px;
    }
}

// desktop
.slide-dialog.slide-dialog--desktop {
    .MuiDialog-container {
        align-items: center;
    }

    .MuiPaper-root {
        margin: 32px;
        width: 100%;
        min-height: auto;
        border-top: none;
        border-radius: 4px;
        box-shadow: none;
    }

    .MuiDialogContent-root {
        padding-top: 0;
    }

    .slide-dialog {
        &__btn-close {
            padding: 6px;
            color: $white;
            width: auto;
            align-self: flex-end;       

            &::before {
                display: none;
            }
        }
    }
    
}