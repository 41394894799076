
/*********************
BREAKPOINTS
*********************/

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px
);

@mixin breakpoint($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);
    
        // Write the media query.
        @media (min-width: $breakpoint-value) {
          @content;
        }
      // If the breakpoint doesn't exist in the map.
      } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
      }
}

@mixin hover-supported {    
  @media (hover: hover) { 
    @content;
  }
}