
@import 'styles/variables';
@import 'styles/mixin';

.progress-Input-range {
    display: flex;
    align-items: center;
    margin: 0 0 1rem 0;

    &__time {
        margin-right: 0.5rem;
    }
    
    &__time-duration {
        margin-left: 0.5rem;
    }

    /* Input range - chrome and safari */
    input[type="range"] {
        --range-progress: 0;
        -webkit-appearance: none;
        position: relative;
        background:$grey-200;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        cursor: pointer;

        &::before {
            content: '';
            height: 2px;
            background: $primary-green;
            width: var(--range-progress);
            border-bottom-left-radius: 2px;
            border-top-left-radius: 2px;
            position: absolute;
            top: 0;
            left: 0;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 8px;
            width: 8px;
            border-radius: 50%;
            border: none;
            background-color: $primary-green;
            cursor: pointer;
            position: relative;
        }

        &:active::-webkit-slider-thumb {
            transform: scale(1.2);
        }
    }

    /* Input range - firefox */
    input[type="range"]::-moz-range-track {
        position: relative;
        background:$grey-200;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        cursor: pointer;
    }

    /* played progress length - firefox */
    input[type="range"]::-moz-range-progress {
        background: $primary-green;
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        height: 2px;
    }

    /* slider thumb - firefox */
    input[type="range"]::-moz-range-thumb {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: $primary-green;
        cursor: pointer;
        border: transparent;
        position: relative;
    }
    /* dragging thumb - firefox */
    input[type="range"]:active::-moz-range-thumb {
        transform: scale(1.2);
    }
}