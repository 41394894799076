.logo-wiretree-ai {
    display: flex;
    width: 32px;
    height: 32px;

    > svg {
        fill: #ADD038;
    }

    &--white {
        > svg {
            fill: #fff;
        }
    }

    &--black {
        > svg {
            fill: #000;
        }
    }
}