@import 'styles/variables';
@import 'styles/mixin';

.song-list-item {
    &[type="button"] {
        background: none;
        border: none;
        width: 100%;
        box-sizing: border-box;
        color: $text-primary;
        text-align: left;
        padding-left: 0;
        padding-right: 0;
    }

    &--active[type="button"] {
        color: $primary-green;
    }

    &--disabled[type="button"] {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &__song-detail {
        display: flex;

        h5 {
            padding: 0;
            margin: 0 0 0.25rem;
            font-size: $font-size-xs;
            max-width: 170px;

            @include breakpoint(sm) {
                font-size: $font-size-m;
                max-width: none;
                white-space: normal;
            }
        }

        small {
            color: $text-hint;
        }
    }

    &__song-img {
        display: flex;
        flex-shrink: 0;
        width: 64px;
        height: 64px;
        border-radius: 8px;
        background-color: $grey-800;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 1rem;
    }

    &__col-right {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        color: $text-hint;
    }

    &__btn-like {
        width: 18px;
        height: 18px;
        margin-right: 8px!important;

        &.liked {
            color: $red;
        }

        svg {
            width: 18px;
            height: 18px;
        }
    }


    &__song-detail--gallery {
        width: 140px;
        margin-right: 1.4rem;
        
        .song-list-item__song-img {
            display: flex;
            flex-shrink: 0;
            width: 140px;
            height: 140px;
            border-radius: 8px;
            background-color: $grey-800;
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 0;
        }

        h5 {
            padding: 0;
            margin: 0.75rem 0 0.2rem 0;
            font-size: $font-size-s;

            @include breakpoint(sm) {
                font-size: $font-size-m;
            }
        }

        small {
            color: $text-hint;
        }
    }
}

.song-list__list--grid {
    .song-list-item {
        &__song-detail--gallery {
            width: auto;
            margin: 0;

            h5 {
                max-width: 120px;
                display: block;
                margin: 0.75rem auto 0.25rem auto;

                @include breakpoint(sm) {
                    font-size: $font-size-m;
                    font-weight: normal;
                }
            }
        }

        &__song-img {
            display: flex;
            flex-shrink: 0;
            width: 140px;
            height: 140px;
            margin: 0 auto;
            background-position: 50% 50%;
            background-size: cover;
        }

        &__section {
            position: relative;
        }

        &__description {
            text-align: center;
        }

        .more-btn {
            display: block;
            position: absolute!important;
            top: 0;
            right: 0;
        }
    }
}