@import 'styles/mixin';
@import 'styles/variables';


.drawer-menu {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 1rem;

    &__list {
        color: $white;
        padding: 0!important;
        width: 100%;
        svg {
            fill: $white;
            width: 24px;
            height: 24px;
        }

        > .MuiListItem-root {
            border-bottom: 1px solid $grey-600;
            border-collapse: collapse;
        }
    }

    &__list-parent {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;

        > .drawer-menu__list-item {
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: 1rem;
        }

        > .drawer-menu__item-text {
            margin: 0;
        }
    }

    &__sub-list {
        width: 100%;
    }

    .list-item__icon {
        min-width: 38px;
    }

    .footer {
        margin-top: auto;
        justify-content: flex-start;
        .text-center {
            text-align: left;
        }
    }
}

// desktop style
.drawer-menu--desktop {
    .drawer-menu__list {
        display: flex;
        justify-content: flex-end;

        > .MuiListItem-root {
            border: none;
        }

        li {
            width: auto;
        }
    }

    .list-item__icon {
        min-width: auto;
    }

    .drawer-menu__item-text span {
        &:hover {
            color: $primary-green;
        }
    }
}

.drawer-menu__submenu-list {
    svg {
        fill: $white;
        width: 24px;
        height: 24px;
    }

    .MuiListItem-root:hover {
        color: $primary-green;
        svg {
            fill: $primary-green;
        }

    }
}