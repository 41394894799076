body {
    .mb-1 {
        margin-bottom: 1rem;
    }
    .mb-2 {
        margin-bottom: 2rem;
    }
    .mb-3 {
        margin-bottom: 3rem;
    }
    .mb-4 {
        margin-bottom: 4rem;
    }
    .mt-1 {
        margin-top: 1rem;
    }
    .mt-2 {
        margin-top: 2rem;
    }
    .mt-3 {
        margin-top: 3rem;
    }
    .mt-4 {
        margin-top: 4rem;
    }
    .my-1 {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .my-2 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .my-3 {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .my-4 {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .text-right {
        text-align: right;
    }

    .text-center {
        text-align: center;
    }

    .small {
        font-size: 0.8rem;
    }

    .flex-align-end {
        display: flex;
        align-items: flex-end;
    }

    .full-width {
        width: 100%;
    }

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }
}