@import 'styles/variables';
@import 'styles/mixin';

.playlists-scroller {
    .playlist-cover {
        margin-right: 1.4rem;

        &__title {
            background-color: rgba($black, 0.75);
            width: 100%;
            padding: 4px;
            bottom: 0px;
            left: 0;
        }
    }

    .playlist__list-item--active {
        .playlist-cover__title {
            color: $primary-green;
        }
    }

    &.disabled {
        .playlist__list-item--active,
        .playlist__list-item {
            margin-bottom: 1.75rem;
        }
    }
}