.form-row {
    display: flex;

    &.inline-item {
        align-items: center;
    }

    .inline-label {
        margin-right: 1rem;
    }
}

.button-section {
    margin-top: 1rem;
    margin-bottom: 1rem;

    button {
        width: 100%;

        @include breakpoint(sm) {
            width: 25%;
        }
    }

    button.delete-button,
    button.cancel-button {
        margin-top: 1rem;

        @include breakpoint(sm) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    button.delete-button,
    button.delete-button.Mui-disabled {
        color: $red;
        border: 1px solid rgba($red, 0.7);
    }

    button.cancel-button ,
    button.cancel-button.Mui-disabled {
        color: $grey-200;
        border: 1px solid rgba($grey-200, 0.5);
    }

    button.cancel-button.Mui-disabled {
        opacity: 0.5;
    }
}

// custom file upload button
.file-upload-button {
    .file-input {
        opacity: 0;
        z-index: -1;
        position: absolute;
    }
}