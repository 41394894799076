@import 'styles/variables';

.footer {
    display: flex;
    justify-content: center;
    color: $text-hint;
    padding: 1rem;
    margin-top: 1rem;

    small {
        font-size: $font-size-xs;
    }
}